<template>
  <div class="checkbox-row">
    <div class="bg-block checkbox-block" v-for="(list, index) in options" :key="index">
      <div class="bg-block-h">
        <h3>{{ list.org_name }}</h3>
        <div class="toggle"></div>
      </div>
      <b-form-checkbox
          :key="'checkall'"
          size="lg"
          v-if="isCheckAll"
          @change="checkAll($event, list, index)"
          class="test"
        >
          {{ i18n['areas-tiles'].tcCheckAllButtonText }}
        </b-form-checkbox>
        <b-form-checkbox
          class="test"
          size="lg"
          :disabled="list.chkdisabled"
          v-for="_option in list.sub_areas"
          v-model="selected"
          :key="_option.org_key"
          :value="_option"
          >{{ _option.org_name }}</b-form-checkbox
        >
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'areas-tiles',
  props: {
    headings: {
      default: '',
      type: String,
      required: true,
    },
    i18n: {
      type: Object,
      default: () => {
        return {
          'areas-tiles': {
            tcCheckAllButtonText: 'Select All',
          },
        }
      },
    },
    items: {
      default: [],
      type: Array,
      required: true,
    },
    checkall: {
      default: false,
      type: Boolean,
    },
    selectedItems: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      options: null,
      selectedOptions: [],
      selected: [],
      heading: null,
      chkdisabled: false,
      isCheckAll: true,
      regionSelected: [],
    }
  },
  mounted() {
    if (!!this.selectedOptions && this.selectedOptions.length > 0) {
      let subAreas = []
      let _selecteditems = []
      let region = this.options.forEach((item) => {
        subAreas = [...subAreas, ...item.sub_areas]
      })
      this.selectedOptions.forEach((item) => {
        let find = subAreas.filter((x) => x.org_key.toUpperCase() === item.org_key.toUpperCase())
        if (find !== null) {
          _selecteditems.push(find[0])
        }
      })
      this.selected = _selecteditems
    }
  },
  created() {
    this.options = this.$props.items
    this.selectedOptions = this.$props.selectedItems

    this.options = this.options.map((item) => {
      return { ...item, ...{ chkdisabled: false } }
    })

    this.heading = this.$props.headings
    const { checkall } = this.$props
    if (checkall) {
      this.checkAll(checkall)
      this.isCheckAll = true
    }
  },
  watch: {
    selected: function (newval, oldval) {
      this.$emit('input', newval.length === 0 ? [] : newval)
    },
  },
  methods: {
    checkAll(isCheck, list, index) {
      if (isCheck && !!list && !!list.sub_areas) {
        list.chkdisabled = true
        this.options[index] = list
        if (!!this.selected && this.selected.length === 0) {
          let append = list.sub_areas.map((item) => {
            item.skip = true
            return item
          })

          this.selected = [...this.selected, ...append]
        } else {
          list.sub_areas.map((el) => {
            let notfound = this.selected.indexOf(el)
            if (notfound < 0) {
              el.skip = true
              this.selected.push(el)
            }
          })
        }
      } else {
        if (!!list) {
          list.chkdisabled = false
          this.options[index] = list
          this.selected = this.selected.filter((el) => {
            el.skip = false
            return list.sub_areas.indexOf(el) < 0
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.custom-checkbox {
  padding-left: 34px;
  line-height: 24px;
  margin-bottom: 10px;
  label {
    font-size: 16px;
    &:before {
      top: 0;
      left: -34px;
      border-radius: 0;
      width: 24px;
      height: 24px;
      border-color: #636363;
    }
    &:after {
      top: 0;
      left: -34px;
      height: 24px;
      width: 24px;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: blue !important;
    border-radius: 0;
    width: 44px !important;
    height: 44px !important;
  }
  label.custom-control-label {
    min-width: 200px;
  }
}
.custom-control-label {
  &:before {
    border-radius: 0 !important;
    width: 24px;
    height: 24px;
    border-color: #f61111 !important;
  }
}

.checkbox-row {
  display: flex;
  flex-wrap: wrap;
  .checkbox-block {
    min-width: 275px;
    margin-right: 20px;
    padding: 24px 20px 35px 30px;
    // border: 1px solid #e9e9e9;
    border-radius: 6px;
    // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    @include breakpoint(sm) {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 0;
    }
  }
  .bg-block-h {
    display: flex;
    margin-bottom: 15px;
    h3 {
      flex: 1 1 auto;
      margin: 0;
      color: $gray-200;
      font-family: $open-sans;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: initial;
      line-height: 22px;
      text-transform: none;
    }
    .toggle {
      height: 25px;
      width: 25px;
      flex: 0 0 auto;
      border: 2px solid $gray-200;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before,
      &:after {
        content: '';
        display: block;
        width: 7px;
        height: 2px;
        border-radius: 2px;
        transform: rotate(45deg);
        transform-origin: bottom;
        background-color: $gray-200;
        right: -1px;
        position: relative;
      }
      &:after {
        left: -1px;
        right: auto;
        transform: rotate(-45deg);
      }
    }
  }
  label {
    font-size: 16px;
    line-height: 22px;
  }
}
.test .custom-control-label::before {
  border-radius: 0 !important;
  width: 24px;
  height: 24px;
  border-color: red !important;
}

input[type='checkbox'] label::before {
  border-radius: 0 !important;
  width: 24px;
  height: 24px;
  border-color: red !important;
}
</style>
