<template>
  <div>
    <b-button-group v-for="(item, index) in items" :key="index">
      <b-button v-if="item.selected" :variant="'primary'" @click="switchItem(item)">{{ item.text }}</b-button>
      <b-button v-else :variant="'outline-primary'" @click="switchItem(item)">{{ item.text }}</b-button>
    </b-button-group>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'switch-item',
  props: {
    list: {
      type: Array,
      default: [],
      required: true,
    },
  },
  data() {
    return {
      items: [],
    }
  },
  watch: {
    list: function(newVal, oldVal) {
      this.items = newVal
    }
  },
  methods: {
    switchItem(item) {
      item.selected = true
      this.items = this.items.map((p) => {
        if (p.text === item.text) {
          return item
        }
        p.selected = false
        return p
      })
      this.$emit('input', item)
    },
  },
  created() {
    if (this.$props.list.length > 0) {
      this.items = this.$props.list
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';
</style>